import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import useEnvContext from '../hooks/useEnvContext';
import {examplesRoute, howItWorksRoute, pricesRoute, privacyPolicyRoute, termsOfUseRoute, whyQrCjenikRoute} from '../routes';
import {CONTAINER_WIDTH, TEXT_COLOR} from '../constants';

export default function Footer() {
	const {appUrl} = useEnvContext();

	return (
		<StyledFooter>
			<div>
				<div>
					<a href={appUrl('/prijava')}>Prijava</a>
					<a href={appUrl('/registracija')}>Registracija</a>
					<Link to={termsOfUseRoute()}>Uvjeti korištenja</Link>
					<Link to={privacyPolicyRoute()}>Politika privatnosti</Link>
				</div>
				<div>
					<Link to={howItWorksRoute()}>Kako QR usluga radi?</Link>
					<Link to={examplesRoute()}>Primjeri</Link>
					<Link to={whyQrCjenikRoute()}>Zašto odabrati QR-Cjenik.hr</Link>
					<Link to={pricesRoute()}>Cijene</Link>

					<div className="social-links">
						<OutboundLink href="https://www.facebook.com/QRCjenik" target="_blank" rel="noreferrer noopener">
							<FontAwesomeIcon icon={faFacebook} />
						</OutboundLink>
						<OutboundLink href="https://www.instagram.com/qr.cjenik/" target="_blank" rel="noreferrer noopener">
							<FontAwesomeIcon icon={faInstagram} />
						</OutboundLink>
					</div>
				</div>
				<div>
					<p>QR Cjenik je proizvod firme Splendido Solutions d.o.o. i u potpunosti je razvijen u Varaždinu, Hrvatska.</p>
					<p>&nbsp;</p>
					<p>OIB: 42172723674</p>
					<p>IBAN: LT28 3250 0782 3329 7239</p>
					<p>
						email: <u>kontakt(at)qr-cjenik.hr</u>
					</p>
					<p>
						tel:{' '}
						<a href="tel:+385976008701" className="phone-number">
							+385 97 600 8701
						</a>
						.
					</p>
				</div>
			</div>
		</StyledFooter>
	);
}

const StyledFooter = styled.footer`
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;

	> div {
		// wrapper
		width: 100%;
		display: flex;
		flex-direction: column;

		> div {
			// every "column"
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			padding: 1rem;
			box-sizing: border-box;
			text-align: center;

			&:nth-child(1) {
			}

			&:nth-child(3) p {
				// text about
				font-size: 0.8em;
				color: #888888;
				line-height: 1.5;
			}

			&:nth-child(3) {
				// links to signup, login, terms, privacy
			}

			> a {
				display: block;
				font-size: 0.925em;
				color: #888888;
				text-align: center;
				padding: 0.35rem 0;
				box-sizing: border-box;

				@media (hover: hover) and (pointer: fine) {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.phone-number {
		color: #888888;
		text-decoration: underline;
	}

	.social-links {
		margin-top: 0.75rem;

		> a {
			display: inline-block;
			vertical-align: middle;
			font-size: 2rem;
			padding: 0.5rem;
			color: ${TEXT_COLOR};
		}
	}

	@media (min-width: 788px) {
		> div {
			max-width: ${CONTAINER_WIDTH};
			margin: 0 auto;

			align-items: flex-start;
			flex-wrap: nowrap;
			flex-direction: row;

			> div {
				padding: 2.5rem;

				&:nth-child(3) p {
					text-align: left;
				}

				&:nth-child(1) {
					order: 3;
				}

				&:nth-child(2) {
					order: 2;
				}

				&:nth-child(3) {
					order: 1;
				}

				&:nth-child(3) {
					// links to signup, login, terms, privacy
					> a {
						text-align: left;
					}
				}
			}
		}
	}
`;
